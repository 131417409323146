
import 'normalize.css/normalize.css'
import './styles/index.scss'
import {
   domIsReady,
   isTouchMode,
   environment,
   getCookie,
   setCookie
} from './js/utils'
import SliderIntro from './js/slider-intro'
import PromoBoxNavigation from './js/promo-box-navigation'
import {
   IMG_PATH
} from './js/paths'
import SimpleBar from 'simplebar'
import Modal from './js/modal'
import './js/promo-message'
import './js/contact-form'

const MODAL_FLYER_HIDE = 'modal_flyer_hide'

const importAll = (r) => {
   return r.keys().map(r);
}

const handleIntroNavButtonsDisplay = (wrapperEl, sectionPromEl) => {
   if (wrapperEl && sectionPromEl) {
      const sectionPromElTop = sectionPromEl.offsetTop
      const introNavBtnPrev = document.querySelector('.js-swiper-intro-button-prev')
      const introNavBtnNext = document.querySelector('.js-swiper-intro-button-next')

      if (!introNavBtnNext || !introNavBtnPrev) return false

      wrapperEl.addEventListener('scroll', e => {
         if (wrapperEl.scrollTop > sectionPromElTop + (sectionPromEl.offsetHeight / 1.1)) {
            introNavBtnNext.style.display = 'none'
            introNavBtnPrev.style.display = 'none'
         } else {
            introNavBtnNext.style.display = ''
            introNavBtnPrev.style.display = ''
         }
      })
   }
}

const disallowAZinput = (inputs) => {
   if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
         inputs[i].addEventListener('keypress', e => {
            if (/[A-z]/i.exec(e.key)) {
               e.preventDefault()
            }
         })
      }
   }
}

// Import promo flyers images
importAll(require.context('./assets/img/promo-flyers/', false, /\.(jpe?g)$/));

const addTouchModeClass = () => {
   if (isTouchMode()) {
      document.documentElement.classList.add('touch-mode')
   }
}
addTouchModeClass()

// Init promo lists scrollbar
const initPromoListScrollBar = (promoListUlElems) => {
   if (promoListUlElems && promoListUlElems.length > 0) {
      for (let e = 0; e < promoListUlElems.length; e++) {
         new SimpleBar(promoListUlElems[e])
      }
   }
}

const handleSliderIntroSlideChange = (s) => {
   const BOXNAV_ALREADY_INITIALIZED_CLASS = 'js-boxnav-already-initialized'
   const curPromoListEl = document.querySelector('.js-promo-list.active')
   const curPromoBoxContainer = document.querySelector('.js-promo-box-container.active')
   const promoListEl = document.querySelector(s.slideActiveEl.dataset.promoList)
   const promoBoxContainer = document.querySelector(s.slideActiveEl.dataset.promoBoxContainer)
   
   curPromoListEl && curPromoListEl.classList.remove('active')
   curPromoBoxContainer && curPromoBoxContainer.classList.remove('active')
   promoListEl && promoListEl.classList.add('active')
   promoBoxContainer && promoBoxContainer.classList.add('active')

   // Init promo box navigation if is active for first time
   if (!s.slideActiveEl.classList.contains(BOXNAV_ALREADY_INITIALIZED_CLASS)) {
      new PromoBoxNavigation(s.slideActiveEl.dataset.promoList, {
         selectors: {
            boxContainer: s.slideActiveEl.dataset.promoBoxContainer,
            boxInner: `${s.slideActiveEl.dataset.promoBoxContainer} .js-box-content-wrapper-inner`,
         }
      })
      s.slideActiveEl.classList.add(BOXNAV_ALREADY_INITIALIZED_CLASS)
   }
}

const initSliderIntro = () => {
   // Init Slider intro
   const sliderIntro = new SliderIntro('.js-swiper-intro', {
      totalSlides: 6,
      selectors: {
         buttonPrev: '.js-swiper-intro-button-prev',
         buttonNext: '.js-swiper-intro-button-next'
      },
      onSlideChange: (s) => {
         handleSliderIntroSlideChange(s)
      }
   })

   handleSliderIntroSlideChange(sliderIntro)
}

const handleFlyerBtnMessageAction = () => {
   const btnOpen = document.getElementById('btn-open-message-form')
   const btnClose = document.getElementById('btn-close-message-form')
   const formContainer = document.getElementById('promo-message-form')
   if (formContainer) {
      if (btnOpen) {
         btnOpen.addEventListener('click', e => {
            e.stopPropagation()
            formContainer.classList.add('visible')
         })
      }
   
      if (btnClose) {
         btnClose.addEventListener('click', e => {
            e.preventDefault()
            formContainer.classList.remove('visible')
         })
      }
   }
}

const mustDisplayModalFlyer = () => {
   if (!getCookie(MODAL_FLYER_HIDE)) {
      return true
   }
   return false
}

const getFlyerImagePath = (imageName) => {
   return (environment === "development") 
   ? `${IMG_PATH}promo-flyers/${imageName}`
   : `${IMG_PATH}${imageName}`
}

;(function(doc, domIsReady) {
   domIsReady(function() {
      const flyerModalImage = doc.getElementById('modal-flyer-image')
      const flyerModalImageLoading = doc.getElementById('modal-flyer-loading')
      let isFlyerModalImgLoading = true
      const wrapperEl = document.getElementById('main-wrapper')
      const sectionPromEl = document.querySelector('.section-promotions')

      initSliderIntro()

      const disAZInputElems = document.querySelectorAll('[data-disallow-az="true"]')
      disallowAZinput(disAZInputElems)

      handleIntroNavButtonsDisplay(wrapperEl, sectionPromEl)

      // Remove flyer modal image loading spinner
      flyerModalImage.onload = () => {
         if (flyerModalImageLoading) {
            if (!isFlyerModalImgLoading) {
               flyerModalImageLoading.classList.remove('visible')
            } else {
               flyerModalImageLoading.classList.add('visible')
            }
         }
      }

      const promoListUlElems = doc.querySelectorAll('.js-promo-list')
      initPromoListScrollBar(promoListUlElems)
      handleFlyerBtnMessageAction()

      if (mustDisplayModalFlyer()) {
         new Modal({
            showAfterPageLoaded: true,
            targetSelector: '#modal-flyer-2',
            onInit: (m) => {
               const loaderEl = m.targetElem.querySelector('#modal-flyer-loading-2'),
               imageEl = m.targetElem.querySelector('#modal-flyer-image-2')
               imageEl.src = getFlyerImagePath(imageEl.dataset.src)
               imageEl.onload = () => {
                  loaderEl.classList.remove('visible')
               }
            },
            onClose: () => {
               setCookie(MODAL_FLYER_HIDE, 1, 'Wed Jul 03 2019 00:00:00 GMT-0500')
            }
         })
      }

      // Init modal flyers
      const openerSelector = '[data-target-id="modal-flyer"]'
      const openerElems = document.querySelectorAll(openerSelector)
      if (openerElems && openerElems.length > 0) {
         for (let o = 0; o < openerElems.length; o++) {
            new Modal({
               openerElem: openerElems[o],
               targetSelector: '#modal-flyer',
               closeSelector: '#modal-flyer .modal-simple-close',
               contentSelector: '#modal-flyer .modal-simple-inner',
               onOpen: (m) => {
                  isFlyerModalImgLoading = false
                  if (flyerModalImage) {
                     flyerModalImage.src = getFlyerImagePath(m.openerElem.dataset.contentImg)
                  } 
               },
               onClose: () => {
                  isFlyerModalImgLoading = true
                  if (flyerModalImage) {
                     flyerModalImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=='
                  }
               }
            })
         }
      }
   })
})(document, domIsReady);
