import {
    isTouchMode
} from './utils'
import deepAssign from './recursiveAssign'

class PromoBoxNavigation {
    constructor(selector, options) {
       this.selector = selector
       this.options = deepAssign({}, {
          selectors: {
             boxItem: '.js-box-promo-item',
             listNavItem: '.js-promo-list-nav'
          }
       }, options)
       
       this.boxContainerEl = document.querySelector(this.options.selectors.boxContainer)
       this.boxInnerEl = document.querySelector(this.options.selectors.boxInner)
       this.boxItemActiveEl = document.querySelector(`${this.options.selectors.boxContainer} ${this.options.selectors.boxItem}`)
       this.navListEl = document.querySelector(this.selector)
       this.navItemActiveEl = document.querySelector(`${this.selector} ${this.options.selectors.listNavItem}`)      
       this.init()      
    }
 
    init() {
       this.listNavItems = document.querySelectorAll(`${this.selector} ${this.options.selectors.listNavItem}`)
       this.boxItems = document.querySelectorAll(`${this.options.selectors.boxContainer} ${this.options.selectors.boxItem}`)
       this.boxItemWidth = this.boxItemActiveEl ? this.boxContainerEl.offsetWidth : 0
       
       this.setActiveBoxItem(this.boxItemActiveEl)
       this.setActiveNavItem(this.navItemActiveEl)
       this.setBoxesAttributes()
       this.attachEvents()
    }
 
    setBoxesAttributes() {
       if (this.boxItems && this.boxItems.length > 0) {
          for (let i = 0; i < this.boxItems.length; i++) {
             this.boxItems[i].style.width = `${this.boxItemWidth}px`
             this.boxItems[i].setAttribute('data-index', i)
          }
       }
    }
 
    setActiveNavItem(newActiveEl) {
        if (this.navItemActiveEl) {
            if (this.navItemActiveEl.classList.contains('active')) {
                this.navItemActiveEl.classList.remove('active')
            }
            this.navItemActiveEl = newActiveEl
            this.navItemActiveEl.classList.add('active')
        }
    }
 
    setActiveBoxItem(newActiveEl) {
        if (this.boxItemActiveEl) {
            if (this.boxItemActiveEl.classList.contains('active')) {
                this.boxItemActiveEl.classList.remove('active')
            }
            this.boxItemActiveEl = newActiveEl
            this.boxItemActiveEl.classList.add('active')
        }
    }
 
    slideTo(index) {
       if (this.boxInnerEl) {
          if (index > 0) {
             this.boxInnerEl.style.transform = `translateX(-${this.boxItemWidth * index}px)`
          } else {
             this.boxInnerEl.style.transform = `translateX(-0)`
          }
       }
       
       const newBoxItemActiveEl = document.querySelector(`${this.options.selectors.boxContainer} ${this.options.selectors.boxItem}[data-index="${index}"]`)
       this.setActiveBoxItem(newBoxItemActiveEl)
    }
 
    attachEvents() {
       const MOUSE_EVT_TYPE = isTouchMode() ? 'click' : 'mouseover'
       
       if (this.listNavItems && this.listNavItems.length > 1) {
          for (let i = 0; i < this.listNavItems.length; i++) {
             this.listNavItems[i].addEventListener(MOUSE_EVT_TYPE, (e) => {
                e.preventDefault()
                this.setActiveNavItem(this.listNavItems[i])
                const newBoxItemActiveEl = document.querySelector(this.listNavItems[i].dataset.target)
                this.slideTo(newBoxItemActiveEl.dataset.index)
             })
          }
       }
    }
 }

 export default PromoBoxNavigation