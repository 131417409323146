// Verify DOM is completely ready
const domIsReady = (function (domIsReady) {
   const isBrowserIeOrNot = function () {
      return (!document.attachEvent || typeof document.attachEvent === "undefined" ? 'not-ie' : 'ie');
   }

   domIsReady = function (callback) {
      if (callback && typeof callback === 'function') {
         if (isBrowserIeOrNot() !== 'ie') {
            document.addEventListener("DOMContentLoaded", function () {
               return callback();
            });
         } else {
            document.attachEvent("onreadystatechange", function () {
               if (document.readyState === "complete") {
                  return callback();
               }
            });
         }
      } else {
         console.error('The callback is not a function!');
      }
   }

   return domIsReady;
})({});


// Helper to add event listeners to element
const attachEvent = (elements, type, listener, callback) => {
   if (typeof elements !== "undefined" && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
         elements[i].addEventListener(type, listener)
         if (callback) callback(elements[i])
      }
   }
}

// Helper to remove event listeners to element
const detachEvent = (elements, type, listener, callback) => {
   if (typeof elements !== "undefined" && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
         elements[i].removeEventListener(type, listener)
      }
   }
}

// Show an element
const fadeIn = (elem, delay) => {

	// Get the natural height of the element
	const getHeight = function () {
		elem.style.display = 'block'; // Make it visible
		var height = elem.scrollHeight + 'px'; // Get it's height
		elem.style.display = ''; //  Hide it again
		return height;
	};

	var height = getHeight(); // Get the natural height
	elem.classList.add('fade-in'); // Make the element visible
	elem.style.height = height; // Update the max-height

	// Once the transition is complete, remove the inline max-height so the content can scale responsively
	window.setTimeout(() => {
		elem.style.height = ''
	}, delay || 350)

}

// Hide an element
const fadeOut = (elem, delay) => {

	// Give the element a height to change from
	elem.style.height = elem.scrollHeight + 'px';

	// Set the height back to 0
	window.setTimeout(() => {
		elem.style.height = '0';
	}, 1);

	// When the transition is complete, hide it
	window.setTimeout(() => {
		elem.classList.remove('fade-in');
	}, delay || 350);

}

const isTouchMode = () => {
   const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
   const mq = (query) => {
     return window.matchMedia(query).matches;
   }
 
   if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
     return true;
   }
 
   // include the 'heartz' as a way to have a non matching MQ to help terminate the join
   // https://git.io/vznFH
   const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
   return mq(query);
}

const setStyleWithVendors = (element, property, value) => {
   if (typeof element !== "undefined") {
      element.style["webkit" + property] = value;
      element.style["moz" + property] = value;
      element.style["ms" + property] = value;
      element.style["o" + property] = value;
   }
}

const environment = (() => {
   const ENV_PROD_CLASS = 'env-prod'
   const ENV_DEV_CLASS  = 'env-dev'

   if (document.body.classList.contains(ENV_DEV_CLASS)) {
      return "development"
   }
   if (document.body.classList.contains(ENV_PROD_CLASS)) {
      return "production"
   }

   return null
})()

const validEmail = email => {
   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}

const validPhoneNumber = phoneNumber => {
   const REGEX = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
   if (phoneNumber) {
      return REGEX.exec(phoneNumber)
   }

   return false
}

const getAPIURL = () => {
   return process.env.NODE_ENV === "development" 
      ? 'http://localhost:8000/api' 
      : 'https://detouroper.travel/api'
}

const getCookie = (name) => {
	let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop().split(";").shift();
}

const setCookie = (name, value, expires) => {
   document.cookie = name + `=${value};expires=${expires}`;  
}

module.exports = {
   domIsReady : domIsReady,
   attachEvent: attachEvent,
   detachEvent: detachEvent,
   isTouchMode: isTouchMode,
   environment: environment,
   validEmail: validEmail,
   validPhoneNumber: validPhoneNumber,
   getAPIURL: getAPIURL,
   getCookie: getCookie,
   setCookie: setCookie
}

