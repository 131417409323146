(function() {

    const validEmail = require('./utils').validEmail
    const validPhoneNumber = require('./utils').validPhoneNumber
    const _fetch = require('whatwg-fetch').fetch
    const getAPIURL = require('./utils').getAPIURL
    
    // Check if browser supports fetch API
    if (typeof window.fetch === "undefined") {
        window.fetch = _fetch
    }

    const ENDPOINT_URL = `${getAPIURL()}/mail/promotion`

    const formElem = document.getElementById('form-promo-message')
    const submitBtn = formElem.querySelector('[type="submit"]')
    const submitElem = formElem.querySelector('[type="submit"] > strong')
    const submitIconEl = formElem.querySelector('[type="submit"] .fa-send')
    const successMsgEl = formElem.querySelector('.success-message')
    const INPUT_NAME_PREFIX = ''

    if (!formElem) throw new Error("form element doesn't exists in DOM")

    const renderMessageError = (input, message) => {
        if (input) {
            input.classList.add('has-error')
            input.nextElementSibling.innerHTML = message
        }
        if (!message) {
            input.classList.remove('has-error')
        }
    }

    const validateInputs = inputElems => {
        let msgErrors = {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
        let isNameValid = false
        let isEmailValid = false
        let isPhoneValid = false
        let isMessageValid = false

        if (typeof inputElems === "undefined") throw new Error('no params given')

        const { name, email, phone, message } = inputElems

        // validate input 'name'
        if (name) {
            // validate empty name
            msgErrors.name = (!name.value || name.value.length === 0) ? 'Debe ingresar sus nombres' : ''
            isNameValid = name.value.length > 0
            renderMessageError(name, msgErrors.name)
            
        }

        // validate input 'email'
        if (email) {
            // validate empty email
            msgErrors.email = (!email.value || email.value.length === 0) ? 'Debe ingresar un email' : ''
            msgErrors.email = (!validEmail(email.value)) ? 'El email ingresado no es válido' : ''
            isEmailValid = email.value.length > 0 && validEmail(email.value)
            renderMessageError(email, msgErrors.email)
            
        }

        // validate input 'phone'
        if (phone) {
            // validate empty phone
            msgErrors.phone = (!phone.value || phone.value.length === 0) ? 'Debe ingresar un número de contacto' : 
                ((phone.value.length < 7 || !validPhoneNumber(phone.value)) ? 'Debe ingresar un número válido' : '')
            isPhoneValid = phone.value.length >= 7 && validPhoneNumber(phone.value)
            renderMessageError(phone, msgErrors.phone)
            
        }

        // validate input 'message'
        if (message) {
            // validate empty message
            msgErrors.message = (!message.value || message.value.length < 4) ? 'Debe ingresar un mensaje' : ''
            isMessageValid = message.value.length >= 4
            renderMessageError(message, msgErrors.message)
            
        }

        return isNameValid && isEmailValid && isPhoneValid && isMessageValid
    }

    submitBtn.addEventListener('click', e => {
        e.preventDefault()

        if (formElem.className.indexOf('is_submitting') !== -1) {
            return sendData()
        }

        if (successMsgEl) successMsgEl.style.display = 'none'

        const emailElem = formElem.elements[`${INPUT_NAME_PREFIX}email`]
        const nameElem = formElem.elements[`${INPUT_NAME_PREFIX}name`]
        const phoneElem = formElem.elements[`${INPUT_NAME_PREFIX}phone`]
        const messageElem = formElem.elements[`${INPUT_NAME_PREFIX}message`]

        const isValid = validateInputs({
            email: emailElem,
            name: nameElem,
            message: messageElem,
            phone: phoneElem
        })

        if (isValid) grecaptcha.execute(grecaptchas[1])
    })

    function sendData() {

        const formData = new FormData(formElem)

        if (submitElem && submitIconEl) {
            submitElem.innerHTML = 'Enviando ...'
            submitIconEl.classList.add('spinner')
        }
        fetch(ENDPOINT_URL, {
            method: 'POST',
            body: formData,
            //mode: 'no-cors'
        })
        .then(response => response.json())
        .then(() => {
            //console.log('Success:', response)
            if (successMsgEl) successMsgEl.style.display = 'inline-block'
            submitElem.innerHTML = 'Enviar'
            submitIconEl.classList.remove('spinner')
            formElem.reset()
        })
        .catch(error => {
            console.error('Error:', error)
            submitElem.innerHTML = 'Enviar'
            submitIconEl.classList.remove('spinner')
        })
    }
    
})()