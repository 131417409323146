import deepMerge from './recursiveAssign'
import { Swiper, Pagination, Navigation } from 'swiper/dist/js/swiper.esm'
import '../assets/img/dennis-van-den-worm.jpg'

Swiper.use([Pagination, Navigation])

const BUTTON_LABEL_SELECTOR = '.js-swiper-button-label'

class SlideIntro {
   constructor(selector, options) {
      this.selector = selector
      this.options = deepMerge({}, {
         totalSlides: 1,
         selectors: {
            slideActiveMainText: '#slide-active-main-text',
            buttonPrev: '.js-swiper-intro .swiper-button-prev',
            buttonNext: '.js-swiper-intro .swiper-button-next',
         }
      }, options)
      this.slideMainTextEl = document.querySelector(this.options.selectors.slideActiveMainText)
      this.activeIndex = 0
      this.nextIndex = 1
      this.prevIndex = this.options.totalSlides > 1 ? this.options.totalSlides - 1 : 0
      this.slideMainText = ''
      this.init()
   }

   init(){
      this.initSwiper()
   }  

   initSwiper() {
      let _this = this
      
      this.swiperInstance = new Swiper(this.selector, {
         loop: true,
         navigation: {
            prevEl: _this.options.selectors.buttonPrev,
            nextEl: _this.options.selectors.buttonNext
         },
         init: false
      })

      this.onInit()
      this.onSlideChange()
      this.swiperInstance.init()
   }

   onInit() {
      this.swiperInstance.on('init', () => {
         this.activeIndex = this.swiperInstance.realIndex
         this.handleNavigationLabel()
         this.setActiveMainText()
         if (this.slideMainTextEl) {
            this.slideMainTextEl.innerHTML = this.slideMainText
         }
         this.setBackgrounds()
      })
   }

   onSlideChange() {
      this.swiperInstance.on('slideChangeTransitionEnd', () => {
         this.activeIndex = this.swiperInstance.realIndex
         this.prevIndex   = this.activeIndex === 0 ? this.options.totalSlides - 1 : this.activeIndex - 1
         this.nextIndex   = this.activeIndex === (this.options.totalSlides - 1) ? 0 : this.activeIndex + 1
         
         this.handleNavigationLabel()
         this.setActiveMainText()
         if (this.slideMainTextEl) {
            this.slideMainTextEl.innerHTML = this.slideMainText
         }
         if (typeof this.options.onSlideChange === "function") {
            this.options.onSlideChange(this)
         }
      })
   }

   setActiveMainText() {
      this.slideActiveEl = this.swiperInstance.el.querySelector(`[data-swiper-slide-index="${this.activeIndex}"]`)
      this.slideMainText = this.slideActiveEl.dataset.label
   }

   handleNavigationLabel() {
      this.slidePrevEl = this.swiperInstance.el.querySelector(`[data-swiper-slide-index="${this.prevIndex}"]`)
      this.slideNextEl = this.swiperInstance.el.querySelector(`[data-swiper-slide-index="${this.nextIndex}"]`)
      this.btnPrevEl = this.swiperInstance.navigation.prevEl
      this.btnNextEl = this.swiperInstance.navigation.nextEl

      if (this.slidePrevEl && this.btnPrevEl) {
         this.btnPrevEl.querySelector(BUTTON_LABEL_SELECTOR).innerHTML = this.slidePrevEl.dataset.label
      }

      if (this.slideNextEl && this.btnNextEl) {
         this.btnNextEl.querySelector(BUTTON_LABEL_SELECTOR).innerHTML = this.slideNextEl.dataset.label
      }
   }

   setBackgrounds() {
      this.swiperInstance.slides.each((i, slide) => {
         slide.style.backgroundImage = slide.dataset.background
      })
   }
}

export default SlideIntro