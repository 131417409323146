import deepAssign from './recursiveAssign'

const CLASS_VISIBLE = 'visible'

export default class Modal {
    constructor(options) {
        this.options = deepAssign({}, {
            openerSelector: '.js-modal-opener',
            targetSelector: '.js-modal-target',
            closeSelector: '.js-modal-close',
            contentSelector: '.js-modal-inner'
        }, options)
        this.onClickOpenerElem  = this.onClickOpenerElem.bind(this)
        this.onClickCloseElem   = this.onClickCloseElem.bind(this)
        this.onClickContentElem = this.onClickContentElem.bind(this)
        this.init()
    }

    init() {
        this.openerElem  = this.options.openerElem || document.querySelector(this.options.openerSelector)
        this.contentElem = document.querySelector(this.options.contentSelector)
        this.attachOpenEvents()

        this.targetElem = document.querySelector(this.options.targetSelector)
        if (this.targetElem) {
            this.closeElem = document.querySelector(this.options.closeSelector)
        }

        if (this.options.showAfterPageLoaded) {
            this.targetElem.classList.add('visible')
            this.open()
        }
        typeof this.options.onInit === "function" && this.options.onInit(this)
    }

    open() {
        if (this.targetElem) {
            this.targetElem.classList.add(CLASS_VISIBLE)
            this.attachCloseEvents()
            if (typeof this.options.onOpen === "function")
                this.options.onOpen(this)
        }
    }

    close() {
        if (this.targetElem) {
            this.targetElem.classList.remove(CLASS_VISIBLE)
            this.detachCloseEvents()
            if (typeof this.options.onClose === "function")
                this.options.onClose(this)
        }
    }

    attachOpenEvents() {
        this.openerElem && this.openerElem.addEventListener('click', this.onClickOpenerElem)
        this.contentElem && this.contentElem.addEventListener('click', this.onClickContentElem)
    }

    attachCloseEvents() {
        this.closeElem && this.closeElem.addEventListener('click', this.onClickCloseElem)
        this.targetElem && this.targetElem.addEventListener('click', this.onClickCloseElem)
    }

    detachCloseEvents() {
        this.closeElem && this.closeElem.removeEventListener('click', this.onClickCloseElem)
        this.targetElem && this.targetElem.removeEventListener('click', this.onClickCloseElem)
        this.contentElem && this.contentElem.removeEventListener('click', this.onClickContentElem)
    }

    onClickOpenerElem(e) {
        e.preventDefault()
        this.open()
    }

    onClickCloseElem(e) {
        e.preventDefault()
        this.close()
    }

    onClickContentElem(e) {
        e.stopPropagation()
    }
}